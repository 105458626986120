<template lang="pug">
.ce-hand-over
  .ce-title-h6 Сдать кассу
  form.ce-hand-over-form(
    @submit.prevent="submitHandler"
  )
    ce-dropdown.ce-hand-over-wallet(
      v-model="currentWallet"
      :options="getActiveWallets"
      :error="errors.currentWallet"
      :option-value="null"
      label="Кошелёк"
      @update:model-value="clearError('currentWallet')"
    )
      template(#option="{ option }")
        span {{ option.extType ?? option.type }}
      template(#value="{ value }")
        span {{ value ? value.extType ?? value.type : '' }}
    ce-dropdown.ce-hand-over-account(
      v-model="currentAccount"
      :options="getActiveAccounts(currentWallet?.walletId)"
      :disabled="!currentWallet"
      :error="errors.currentAccount"
      :option-value="null"
      label="Аккаунт"
      @update:model-value="clearError('currentAccount')"
    )
      template(#option="{ option }")
        span {{ option.accountType }}
      template(#value="{ value }")
        span {{ value ? value.accountType : '' }}
    ce-dropdown(
      v-model="currentCurrency"
      :options="getAccountCurrencies(currentWallet?.walletId, currentAccount?.accountId)"
      :disabled="!currentAccount"
      :error="errors.currentCurrency"
      :option-value="null"
      label="Валюта"
      @update:model-value="clearError('currentCurrency')"
    )
      template(#option="{ option }")
        span {{ option }}
      template(#value="{ value }")
        span {{ value ? value : '' }}
    ce-text-field.ce-hand-over-amount(
      :model-value="cashAmount"
      :placeholder="$t('rp_cash_input_amount_placeholder')"
      :error="errors.cashAmount"
      label="Сумма"
      @update:model-value="amountInputHandler"
    )
    ce-text-field.ce-hand-over-amount(
      v-model.trim="cashierId"
      :error="errors.cashierId"
      placeholder="Введите id кассира"
      label="Главный кассир"
      @update:model-value="clearError('cashierId')"
    )
    button.ce-button.ce-button--black(
      :style="{border: 'none'}"
      type="submit"
    ) {{ $t('rp_cash_submit') }}
</template>

<script>
import { CURRENCY_SHORT_CODES, FIAT_CURRENCIES } from "@/constants/auto-exchange";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { Misc } from "@/models/Misc";
import notyAlert from "@/Plugins/NotyAlert";
import CeDropdown from '@/components/common/CeDropdown.vue'
import CeTextField from '@/components/common/CeTextField.vue'
import { controlledInput } from '@/Methods/GlobalMethods'
import Decimal from 'decimal.js'

const transferRequestObj = {
    SenderUserID: localStorage.getItem('user_id'),
    SenderWallet: '',
    SenderAccount: '',
    SenderCurrency: '',
    SenderAmount: '',
    RecipientUserID: '',
    RecipientWallet: '',
    RecipientAccount: '',
    RecipientCurrency: '',
    RecipientAmount: '',
    ProcessType: 'TRANSFER_SENDER_COMMISSION'
}

export default {
  name: "HandOverForm",
  components: { CeTextField, CeDropdown },
  computed: {
    ...mapState([
      'MainWalletInfo'
    ]),
    ...mapGetters([
      'getActiveWallets',
      'getActiveAccounts',
      'getAccountCurrencies',
      'getAccountBalanceObject'
    ]),
    enoughAmount () {
      if (!this.currentWallet || !this.currentAccount || !this.currentCurrency || !this.cashAmount) return false
      const currencyAmount = this.getAccountBalanceObject(this.currentWallet.walletId, this.currentAccount.accountId)[this.currentCurrency]
      return new Decimal(currencyAmount).gte(this.cashAmount)
    }
  },
  data () {
    return {
      FIAT_CURRENCIES,
      currentWallet: null,
      currentAccount: null,
      currentCurrency: null,
      cashierId: '',
      cashAmount: '',
      validationErrorText: '',
      errors: {
        currentWallet: null,
        currentAccount: null,
        currentCurrency: null,
        cashierId: '',
        cashAmount: '',
      },
      transferRequest: {
        SenderUserID: localStorage.getItem('user_id'),
        SenderWallet: '',
        SenderAccount: '',
        SenderCurrency: '',
        SenderAmount: '',
        RecipientUserID: '',
        RecipientWallet: '',
        RecipientAccount: '',
        RecipientCurrency: '',
        RecipientAmount: '',
        ProcessType: 'TRANSFER_SENDER_COMMISSION'
      },
      // exchangeRequest: {
      //   SenderUserID: localStorage.getItem('user_id'),
      //   SenderWallet: '',
      //   SenderAccount: '',
      //   SenderCurrency: '',
      //   SenderAmount: '',
      //   RecipientUserID: '',
      //   RecipientWallet: '',
      //   RecipientAccount: '',
      //   RecipientCurrency: '',
      //   RecipientAmount: '0',
      //   ProcessType: 'EXCHANGE_WITH_BOTH_COMMISSION'
      // }
    }
  },
  methods: {
    ...mapMutations([
      'setShowGlobalConfirm',
      'SetLoaderFlag'
    ]),
    ...mapActions([
      'createNewDepositFiatRequest',
      'createNewTransfer'
    ]),
    amountInputHandler (val, event) {
      this.clearError('cashAmount')
      this.cashAmount = controlledInput({
        event,
        targetProp: this.cashAmount,
        decimal: 0,
        maxDigits: 12
      })
    },
    cashierInputHandler (event) {
      // this.cashierId = controlledInput({
      //   event,
      //   targetProp: this.cashierId,
      //   decimal: 0,
      //   maxDigits: 12
      // })
      this.cashierId = event.target.value
    },
    clearError (field) {
      this.errors[field] = ''
    },
    checkErrors () {
      let hasError = false
      this.validationErrorText = ''
      const fieldNames = [
        'currentWallet',
        'currentAccount',
        'currentCurrency',
        'cashierId',
        'cashAmount',
      ]
      if (!this.enoughAmount) {
        this.errors.cashAmount = true
        hasError = true
        this.validationErrorText = 'Недостаточно средств'
      }
      fieldNames.forEach(field => {
        this.errors[field] = ''
        if (!this[field]) {
          this.errors[field] = true
          hasError = true
          this.validationErrorText = 'Заполнены не все обязательные поля'
        }
      })
      return hasError
    },
    submitHandler () {
      const hasErrors = this.checkErrors()
      // console.log({ hasErrors })
      if (hasErrors) {
        this.$notify({
          title: this.$t('error_title'),
          text: this.validationErrorText,
          type: 'error'
        })
        return
      }
      // const cashCode = `${CURRENCY_SHORT_CODES.getKeyByValue(this.currentCurrency)}Cash`
      // this.exchangeRequest.SenderCurrency = this.currentCurrency
      // this.exchangeRequest.RecipientCurrency = cashCode
      // this.exchangeRequest.SenderAmount = this.cashAmount
      // this.exchangeRequest.RecipientAmount = this.cashAmount
      // this.exchangeRequest.SenderWallet = this.MainWalletInfo.wallet
      // this.exchangeRequest.SenderAccount = this.MainWalletInfo.account
      // this.exchangeRequest.RecipientUserID = this.cashierId
      this.transferRequest.SenderCurrency = this.currentCurrency
      this.transferRequest.RecipientCurrency = this.currentCurrency
      this.transferRequest.SenderAmount = this.cashAmount
      this.transferRequest.RecipientAmount = this.cashAmount
      this.transferRequest.SenderWallet = this.currentWallet.walletId
      this.transferRequest.SenderAccount = this.currentAccount.accountId
      this.transferRequest.RecipientUserID = this.cashierId
      this.setShowGlobalConfirm({
        value: true,
        acceptCb: this.confirm,
        cancelCb: () => this.setShowGlobalConfirm({ value: false })
      })
    },
    async confirm (userData) {
      this.transferRequest.key = userData.key
      // this.exchangeRequest.key = userData.key
      try {
        // const response = await this.createNewDepositFiatRequest(this.exchangeRequest)
        const response = await this.createNewTransfer(this.transferRequest)
        if (response.data.status === 200) {
          this.$notify({
            title: 'Успех',
            text: this.$selectMessageText('En', 'transferTransactionCreated'),
            type: 'success'
          })
          this.clearInputs()
        } else {
          this.$notify({
            title: this.$t('error_title'),
            text: this.$selectMessageText('En', 'transferTransactionNotCreated'),
            type: 'error'
          })
        }
      } catch (err) {
        if (err.message === Misc.axiosTimeoutExceptionMsg) {
          notyAlert(this.$t(this.$k.common_timeoutExchangeMsg), this.$t(this.$k.common_ok))
          return
        }
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'error'),
          type: 'error'
        })
      } finally {
        this.setShowGlobalConfirm({ value: false })
        this.SetLoaderFlag(false)
      }
    },
    clearInputs () {
      this.currentCurrency = null
      this.cashAmount = ''
      this.cashierId = ''
      this.currentWallet = null
      this.currentAccount = null
      this.transferRequest = transferRequestObj
    }
  },
  watch: {
    currentWallet () {
      this.currentAccount = null
    },
    currentAccount () {
      this.currentCurrency = null
    },
    cashierId (val) {
      console.log('ADA', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.ce-hand-over {
  &-form {
    padding: unset;
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    & > * {
      align-self: flex-end;
    }
    &-input {
      display: flex;
      align-items: center;
      gap: 5px;
      input {
        flex-grow: 1;
        border: none;
      }
    }
    &-btn {
      margin-top: 20px;
    }
  }
  //&-wallet, &-account {
  //  width: 50%;
  //}
}
</style>
